body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* Root font definitions */
@font-face {
  font-family: "Coast";
  src: url("/assets/fonts/coast-bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Avenir";
  src: url("/assets/fonts/Avenir-Medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Avenir";
  src: url("/assets/fonts/Avenir-Roman-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Avenir";
  src: url("/assets/fonts/Avenir-Black-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
.red-asterisk {
  color: #ae2649;
}

.blue-asterisk {
  color: #007fb8;
}
/* .leaflet {
  height: 300px;
  width: 100%;
} */

.anchor::before {
  content: "";
  display: block;
  height: 75px;
  margin-top: -75px;
  visibility: hidden;
}
